import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Blending images using Laplacian pyramid",
  "component": "none",
  "date": "2019-01-04T18:35:13.234Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8530029164556bbe5faaa2d3bf16c6e3/96287/black_ab.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAP/aAAwDAQACEAMQAAABG4G6iuwpRv5TxbSHa//EABwQAAICAgMAAAAAAAAAAAAAAAABAhIRQQMhQ//aAAgBAQABBQLghFqqZVkHg1UUsnWLJHkzf//EABkRAAIDAQAAAAAAAAAAAAAAAAABAhAREv/aAAgBAwEBPwGS04FX/8QAGBEBAAMBAAAAAAAAAAAAAAAAAAECERL/2gAIAQIBAT8BrbHaWP/EAB4QAAIBAwUAAAAAAAAAAAAAAAABMhARIQISMUFx/9oACAEBAAY/At2pkyY+7MwRZfhNmaP0VP/EAB8QAAICAQQDAAAAAAAAAAAAAAABESExEFFhcUGhwf/aAAgBAQABPyHKp4Q7kGye4XyIVUklDXIromdmKa+xOcVh9hwrjkxF7elx6oeXZ//aAAwDAQACAAMAAAAQsCOA/8QAGREAAgMBAAAAAAAAAAAAAAAAABEBIWGh/9oACAEDAQE/EH7F3pEZ/8QAGBEAAgMAAAAAAAAAAAAAAAAAABARMfD/2gAIAQIBAT8QgGU7/8QAIBABAAIBBAIDAAAAAAAAAAAAAQARIUFRYYFxobHB8P/aAAgBAQABPxACaytWB3fqK35FbhdIuTd7YhcojKZFGpHVSRzdwARu0AO6KGnBFUB3i2V4qBqmjzw9b4n42091P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Lake mead view from helicopter",
            "title": "Lake mead view from helicopter",
            "src": "/static/8530029164556bbe5faaa2d3bf16c6e3/b4294/black_ab.jpg",
            "srcSet": ["/static/8530029164556bbe5faaa2d3bf16c6e3/75985/black_ab.jpg 150w", "/static/8530029164556bbe5faaa2d3bf16c6e3/f93b5/black_ab.jpg 300w", "/static/8530029164556bbe5faaa2d3bf16c6e3/b4294/black_ab.jpg 600w", "/static/8530029164556bbe5faaa2d3bf16c6e3/8e1fc/black_ab.jpg 900w", "/static/8530029164556bbe5faaa2d3bf16c6e3/e5166/black_ab.jpg 1200w", "/static/8530029164556bbe5faaa2d3bf16c6e3/96287/black_ab.jpg 3022w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Laplacian Pyramid as a Compact Image Code for blending images. The above photo of Lake Mead was taken from a helicopter in Nevada USA. I took this photo while on tour of the Grand Canyon. During the flight from Las Vegas to a picnic location in the Grand Canyon passengers were able to take beautiful photos of the Hoover Dam and Lake Mead. `}</p>
    <p>{`Using an image from the flight and another found online`}<a parentName="p" {...{
        "href": "#purplehaze"
      }}>{`[`}{`2`}{`]`}</a>{`, I created a new image with different scenery seen out of the helicopter. The new image titled, ‘Celestial  view out of helicopter’ is a blend of the original Lake Mead photo and a photo by Jack Cain, properly titled Purple Haze`}<a parentName="p" {...{
        "href": "#purplehaze"
      }}>{`[`}{`2`}{`]`}</a>{`.`}</p>
    <p>{`The final blended image was created as a result of the pyramid blending process. This technique enables you to combine images using Gaussian and Laplacian pyramids`}<a parentName="p" {...{
        "href": "#burtadelson"
      }}>{`[`}{`1`}{`]`}</a>{`. In a Gaussian pyramid, images are weighted down using a Gaussian average and scaled down. A Laplacian pyramid is very similar to a Gaussian pyramid but it saves the difference between the blurred levels.`}</p>
    <undefined></undefined>
    <undefined></undefined>
    <p>{`The images were blended in such a way that the background galaxy image replaced the view of lake mead from the foreground image (inside the helicopter). To accomplish this the location of the blend was determined using a mask. Specifically, region between the black and white edges of the mask. With the mask and the two images the blending process can begin. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6fcf82b9e6c29949ad4abafa4a270e8d/96287/mask_ab.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAUGAgP/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHFEmbmwCZpJCpO4pD/xAAcEAACAgIDAAAAAAAAAAAAAAABAgMEEDMAEzT/2gAIAQEAAQUCoVQwxWk6wrB15AwjAwvjg0Wtn//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EACAQAAIBBAEFAAAAAAAAAAAAAAECABASITETM1FxcoP/2gAIAQEABj8C5Xz2FXUC5Q2xLl1S8dMvMU+kT1g8T//EAB8QAAICAgEFAAAAAAAAAAAAAAERADEQIfBBYaGx8f/aAAgBAQABPyEGUD+mehy5do1qChGVHAS0UjshKInQwkCzLOKngvU5vcz/2gAMAwEAAgADAAAAEOAPDP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EACEQAQABAgYDAQAAAAAAAAAAAAERAEEQITFRgaFhcbHw/9oACAEBAAE/EILQtuR15WxVljylZsLj08UIk8hgAASUsJXdmerlJmBzBNGb0tAD5a675r8XZXWUf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Blending mask",
            "title": "Blending mask",
            "src": "/static/6fcf82b9e6c29949ad4abafa4a270e8d/b4294/mask_ab.jpg",
            "srcSet": ["/static/6fcf82b9e6c29949ad4abafa4a270e8d/75985/mask_ab.jpg 150w", "/static/6fcf82b9e6c29949ad4abafa4a270e8d/f93b5/mask_ab.jpg 300w", "/static/6fcf82b9e6c29949ad4abafa4a270e8d/b4294/mask_ab.jpg 600w", "/static/6fcf82b9e6c29949ad4abafa4a270e8d/8e1fc/mask_ab.jpg 900w", "/static/6fcf82b9e6c29949ad4abafa4a270e8d/e5166/mask_ab.jpg 1200w", "/static/6fcf82b9e6c29949ad4abafa4a270e8d/96287/mask_ab.jpg 3022w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Pyramid blending process begins by building Laplacian pyramids. First a Laplacian pyramid of the individual images is created then each layer of each is multiplied to the corresponding Gaussian mask layer. From coarse to fine. Causing each layer to cross blend at the white of the mask.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a2aca9f5e1bfed3705488b3dcd0a818c/a7229/outimg_ehanced.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIDBAUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHJkW3pVOkNZzpJZBA//8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEBESIUH/2gAIAQEAAQUCHZilgA0gdSzupryv/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/AQmz/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQIBAT8BI//EABwQAAEDBQAAAAAAAAAAAAAAABABETEAEiAhkf/aAAgBAQAGPwJ8LuB4R4rQU//EABwQAAICAgMAAAAAAAAAAAAAAAABESEQMUFRgf/aAAgBAQABPyFdmEFJqVPBLM56D2MDqTwKSkDsYMWj/9oADAMBAAIAAwAAABA8y4D/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEQ/9oACAEDAQE/ENODC2//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EDVkKcbW/8QAHhABAAICAwADAAAAAAAAAAAAAQARITFBUYFhobH/2gAIAQEAAT8QI4m2w+e5bbGjKIlG3qc4KuzthyhFT7BVDU4sYA8SisyT7U38PyaJ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Celestial view from helicopter",
            "title": "Celestial view from helicopter",
            "src": "/static/a2aca9f5e1bfed3705488b3dcd0a818c/b4294/outimg_ehanced.jpg",
            "srcSet": ["/static/a2aca9f5e1bfed3705488b3dcd0a818c/75985/outimg_ehanced.jpg 150w", "/static/a2aca9f5e1bfed3705488b3dcd0a818c/f93b5/outimg_ehanced.jpg 300w", "/static/a2aca9f5e1bfed3705488b3dcd0a818c/b4294/outimg_ehanced.jpg 600w", "/static/a2aca9f5e1bfed3705488b3dcd0a818c/8e1fc/outimg_ehanced.jpg 900w", "/static/a2aca9f5e1bfed3705488b3dcd0a818c/e5166/outimg_ehanced.jpg 1200w", "/static/a2aca9f5e1bfed3705488b3dcd0a818c/a7229/outimg_ehanced.jpg 1638w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "205px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eab1382ea1bb0b62f454ea5a6adcbfce/e6c22/lapl_pyr_white.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "999.3333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCADIABQDASIAAhEBAxEB/8QAGQABAQEBAQEAAAAAAAAAAAAAAAIBAwQF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABwABzHRzAAHFg1gJFJAAFLELBoxosAGIFoDeYtA+bXb2dK+WtM+vCa8wZ/8QAHBAAAgIDAQEAAAAAAAAAAAAAABEBAgMSIBBA/9oACAEBAAEFAvicjkc/AhC7QhdsY+96m9TevmvkVNYpjkjPWC+eLjP/xAAYEQACAwAAAAAAAAAAAAAAAAABEBESQP/aAAgBAwEBPwHNCsV//8QAGBEAAgMAAAAAAAAAAAAAAAAAABAREkD/2gAIAQIBAT8BzVIf/8QAGxAAAgIDAQAAAAAAAAAAAAAAATEAECAhMlD/2gAIAQEABj8C99iMToY7ZVcxV//EACEQAAIBAgYDAAAAAAAAAAAAAAABERBhICExQHHxQVGR/9oACAEBAAE/IdlcLhcxySTj5nM57IEMOiu/NO7JtfsdoJUNZ0m8aE1C5Zj1ggz/2gAMAwEAAgADAAAAEPPPPAAMMDDAAMMDDBADDAAEMXQ//8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEED/2gAIAQMBAT8QzQ9zP//EABgRAQEBAQEAAAAAAAAAAAAAAAEAETFA/9oACAECAQE/EPMbNmGnG1v/xAAiEAACAQMFAAMBAAAAAAAAAAABEQAxUWEQICFBkTBxofD/2gAIAQEAAT8Q+Fm59jNz7Gbn3fmTMmZvaNH3pd5Eu8iXeb3uI9xHuNp4Yyzolx5Ev+TJpbOhKDjYAA1kOgqf1tKXT28JhJZNTogWMgVWkShhSQSOIYae5QDuBzjAMR9SqljCcxP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "pyr_white",
            "title": "pyr_white",
            "src": "/static/eab1382ea1bb0b62f454ea5a6adcbfce/e6c22/lapl_pyr_white.jpg",
            "srcSet": ["/static/eab1382ea1bb0b62f454ea5a6adcbfce/75985/lapl_pyr_white.jpg 150w", "/static/eab1382ea1bb0b62f454ea5a6adcbfce/e6c22/lapl_pyr_white.jpg 205w"],
            "sizes": "(max-width: 205px) 100vw, 205px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div className="gap-20"></div>
    <blockquote>
      <h3 parentName="blockquote" {...{
        "id": "references",
        "style": {
          "position": "relative"
        }
      }}>{`References`}<a parentName="h3" {...{
          "href": "#references",
          "aria-label": "references permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <ol parentName="blockquote">
        <li parentName="ol">
          <a name="burtadelson"></a>Burt, Peter J.; Adelson, Edward H. (1983). "The Laplacian Pyramid as a Compact Image Code" (PDF). IEEE Transactions on Communications. 31: 532-540.
        </li>
        <li parentName="ol">
          <a name="purplehaze"></a>Purple haze by Jack Cain, Published on August 1, 2017. https://unsplash.com/photos/-cbijoXPahg/info
        </li>
      </ol>
    </blockquote>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      